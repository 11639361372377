

































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import { UploadFile } from "@/request/common";
import {
  GetBookFiles,
  DelBookFiles,
  UpdateBookFiles,
  DragImg,
  GetBookMulu,
  ConnectChapter,
} from "@/request/storehouse";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private id: any = "";
  private name: any = "";
  private ifShowGuanlian: any = false;
  private data: any = [];
  private cImg: any = {};
  private zhangjies: any = [];
  private guanlianData: any = [];
  private guanlianSure() {
    if (this.guanlianData.length == 0) {
      this.$message.warning("请选择关联章节");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      book_id: this.id,
      file_id: this.cImg.file_id,
      title_id: this.guanlianData[this.guanlianData.length - 1],
    };
    ConnectChapter(this, params, loading).then((res: any) => {
      this.$message.success("关联成功");
      this.ifShowGuanlian = false;
      this.getData();
    });
  }
  private openGuanlian() {
    const params: any = {
      params: {
        book_id: this.id,
        // need_status: false,
      },
    };
    GetBookMulu(this, params).then((res: any) => {
      this.zhangjies = res.titles;
      this.guanlianData = [this.cImg.book_content_id];
      this.ifShowGuanlian = true;
    });
  }
  private upload(file: any) {
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    UploadFile(this, form, loading)
      .then((res: any) => {
        loading.close();
        // this.$emit("addOne", res.full_file_url);
        const params: any = {
          book_id: this.id,
          file_urls: [res.full_file_url],
        };
        const loading2: any = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        UpdateBookFiles(this, params, loading2)
          .then((res: any) => {
            loading2.close();
            this.$message.success("上传成功");
            this.getData();
          })
          .catch(() => {
            loading2.close();
          });
      })
      .catch(() => {
        loading.close();
      });
  }
  private delOne(item: any) {
    this.$confirm("确定删除么？", "删除", {
      customClass: "commonConfirm",
    }).then((res: any) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      const params: any = {
        data: {
          book_id: item.book_id,
          file_id: item.file_id,
        },
      };
      DelBookFiles(this, params, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("删除成功");
          if (this.cImg.file_id == item.file_id) {
            this.cImg = {};
          }
          this.getData();
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  private changeImg(data: any, node: any) {
    this.cImg = data;
    this.init();
  }
  private init() {
    setTimeout(() => {
      if (this.$refs.tree) {
        (this.$refs.tree as any).setCurrentKey(this.cImg);
      }
    }, 100);
  }
  private allowDrop(draggingNode: any, dropNode: any, type: any) {
    if (type == "inner") {
      return false;
    } else {
      return true;
    }
  }
  private nodeDrop() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      book_id: this.id,
      files: this.data,
    };
    DragImg(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("移动成功");
        this.getData();
      })
      .catch(() => {
        loading.close();
      });
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        book_id: this.id,
      },
    };
    GetBookFiles(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
        if (this.data.length > 0) {
          if (!this.cImg.file_id) {
            this.cImg = this.data[0];
            this.init();
          } else {
            this.data.forEach((ele: any) => {
              if (this.cImg.file_id == ele.file_id) {
                this.cImg = ele;
              }
            });
          }
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
    }
  }
}
